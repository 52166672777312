import styles from './ProjectDetails.module.css';
import { Breadcrumb } from 'antd';
export default function ProjectDetails(props) {
    const skillsList = props.skills.map((skill) => <li key={skill}>{skill}</li>)
    return (
        <>
            {/* <Breadcrumb
                items={[
                    {
                        href: '/projects',
                        title: 'Back to Projects',
                    }
                ]}
            /> */}
            <h1 className='FancyHeader'>{props.title}</h1>
            <section className={styles.skills}>
                <h2>Key Skills</h2>
                <ul className={styles.skillsList}>
                    {skillsList}
                </ul>
            </section>

            <section>
                <h2>Project Details</h2>
                <span className={styles.fadedImg}>
                    <img src={props.image} className={styles.detailImg} alt="" />
                </span>
                <article className={styles.article}>
                    {props.details}
                </article>
            </section>
        </>
    )
}