import ProjectDetails from "./ProjectDetails";
export default function HTMLComponents(props) {
    const skills =
        [
            'Self-Contained Components',
            'Agile Workflow',
            'Ensuring design integrity',

        ];
    const image = '/img/tile_components.jpg';
    const details = <>
        <p>
        As more companies maintain internal teams of developers to implement their solutions, design consultancies received smaller scopes for their projects.  In many instances, the goal of a project was to perform the research, analysis and design of a site, with less emphasis on creating the site itself.  Two examples of this are one of the world's largest e-commerce companies, and a Global 500 insurance company who both had internal teams to integrate our designs into their internal environment.
        </p>
        <p>
            In situations such as these, the clients required self-contained components including the HTML, CSS and any JavaScript required for the front-end, which they would tie into their infrastructure. This process ensured that the integrity of the design was maintained, both from the visual look and feel as well as the interaction animations of components. Following the client's Agile requests, I generated HTML/CSS/JS packages and coordinated with their team as they converted my components into functional elements of the site.
        </p>

    </>
    return (
        <ProjectDetails
            title="Front-end components for handoff to clients' developers"
            skills={skills}
            image={image}
            details={details}
        />
    )
}