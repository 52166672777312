import ProjectDetails from "./ProjectDetails";
export default function LDSCom(props) {
    const skills =
        [
            'WordPress Configuration',
            'WordPress Custom Template',
            'WordPress Custom Plugin',
            'WordPress Hosting',
            'PHP',
            'SQL',
            'Implementation',
            'Ongoing Content Maintenance'
        ];
    const image = '/img/tile_meeting.jpg';
    const details = <>
        <p>
            <a href='https://www.lds.com' target='_blank' rel="noreferrer">Logical Design Solutions</a> had gone by various descriptions over the 26 years I was with them, all revolving around using web technology to further the business goals of their global 500 clients. A company in that space needs a website that truly shines, as an example of the quality of service the client can expect.
        </p>
        <p>
            Over the course of my years there, I became responsible for the company's own site, in addition to the client projects I was assigned to. I built earlier versions of the site using the technology of the time, including pure HTML/CSS, Visual Basic.Net, Drupal and Joomla.
        </p>
        <p>
            The most recent version of the site is built in WordPress, using a custom template that I helped create in PHP.  We also created custom plugins to aid in the process of storing settings and frequently reused content such as the company contact information.  For the front-end, the template has its own visual and behavioral defaults.  Each article, case study, job and other category of page has its category styles plus optional per-page CSS and JavaScript attached to it through Advanced Custom Fields (ACF).  We used Bootstrap CSS to standardize the UI, jQuery for most interaction and DOM manipulation, and GSAP for animation.
        </p>
    </>
    return (
            <ProjectDetails
                title="WordPress B2B Site for Consulting Company"
                skills={skills}
                image={image}
                details={details}
            />
    )
}