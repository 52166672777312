import ProjectDetails from "./ProjectDetails";
export default function ShP(props) {
    const skills =
        [
            'SharePoint Templates',
            'VB.Net',
            'Document and Content Migration',
            'Client Partnership'

        ];
    const image = '/img/tile_insurance.jpg';
    const details = <>
        <p>
            A major US healthcare company hired my employer to create a new HR intranet to house their internal documentation, forms, policies and related content.  Once the design was done, I was part of the team creating the template pages in VB.Net.  I then instantiated several hundred pages based on these templates, populated with content from their original site and updated as necessary to meet newer business requirements.  I also migrated documents from their old site into the appropriate libraries, to increase findability both through organic searches and site search.
        </p>
        <p>
            After the original launch was complete, I was assigned to maintain the site as a partner to an employee of the client.  For two years, my counterpart and I kept the content fresh, managed new documents, and planned and created new sub-sites all while ensuring that the original design intentions were preserved.  As this was a client several hundred miles away before video meetings were readily available, it often required working jointly while on hours-long phone calls to coordinate properly.
        </p>
    </>
    return (
        <ProjectDetails
            title="SharePoint Templates and Multi-year Maintenance"
            skills={skills}
            image={image}
            details={details}
        />
    )
}