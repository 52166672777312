import ProjectDetails from "./ProjectDetails";
export default function IT(props) {
    const skills =
        [
            'Office 365 Administration',
            'Desktop Suppport',
            'Server Suppport',
            'Telephony & Remote Meetings',
            'Office Buildout & Closures',
            'Physical Security',
            'Ad-hoc Process Automation',

        ];
    const image = '/img/tile_binary.jpg';
    const details = <>
        <p>
            As developers often do in smaller companies, I filled the role of IT Support concurrently with that of developer for many years.  This included desktop and server support, infrastructure, and a host of related responsibilities.  On any given day I could be managing Active Directory, replacing a broken part on a laptop, porting telephony or meeting services between vendors, running CAT-5 cable to relocate a security camera, or simply helping a user with a software issue.
        </p>
                
        <p>
            At one point while helping an executive with a laptop issue, he mentioned that he was spending a full day every week downloading timecard data from HR as a raw CSV file, and manually massaging it through Excel to produce an hourly report to his client with the appropriate grouping, calculations, and formatting.  This process was taking 8 hours of his week repeatedly, on a project that was forecasted to last a year or more.  Adding up that lost time would result in an enormous drain on his productivity.  I documented his existing process, and wrote an Excel (VB.Net) macro which duplicated it.  What took an entire day previously was reduced to loading the CSV file, pressing a button and watching for approximately 30 seconds while the macro performed its tasks.  This is the benefit of wearing both hats - as a pure developer I may not have had the interaction that started that conversation.  As pure tech support, I may not have had the programming experience to solve it.
        </p>

    </>
    return (
        <ProjectDetails
            title="Supported IT Department as necessary"
            skills={skills}
            image={image}
            details={details}
        />
    )
}