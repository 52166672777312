import ProjectDetails from "./ProjectDetails";
export default function HomeReno(props) {
    const skills =
        [
            'Wordpress Templates',
            'Wordpress Plugins',
            'User Documentation and Training',

        ];
    const image = '/img/tile_kitchen.jpg';
    const details = <>
        <p>
            A local home renovation company approached me for a site to display his work. He does varied and very impressive jobs ranging from tiling a bathroom to ripping a kitchen down to the studs and rebuilding it from scratch. Without a website, when the owner wanted to show potential clients his previous work, he would flip through pictures on his phone either in person or select a few related images and send them to the client.
        </p>
        <p>
            In today's digital world, a potential client's first impression of a company is their website. Fair or not, an impressive contractor without an equally impressive website will be perceived as inferior to one with a commanding web presence. Working with the owner, we decided a WordPress site would be best so that he could manage the content himself without needing to rely on me for updates. Using pre-built templates would limit the available options for personalization, so I designed a site based on his needs and created the PHP template from the ground up. The template pulls content from the standard WordPress database, as well as from installed plugins and custom content types, bringing it all forward into a visualization tailored to the company's personality.
        </p>
        <p>
            I created documentation which enabled the owner to maintain his gallery of before/after images, project descriptions, and page content.  Along with some personal training on navigating through WordPress, he was able to maintain the site himself, returning to me only for adding additional functionality as needed.
        </p>

    </>
    return (
        <ProjectDetails
            title="Portfolio site for a local home remodeling company"
            skills={skills}
            image={image}
            details={details}
        />
    )
}