import ProjectDetails from "./ProjectDetails";
export default function Domino(props) {
    const skills =
        [
            'Lotus Domino',
            'LotusScript',
            'Continuous Learning',

        ];
    const image = '/img/personal-hygiene.png';
    const details = <>
        <p>
        My employer had a long-standing contract with a major multinational consumer products company for several internal tools including a site to keep track of new product ideas and innovations coming from their employees.  Using Lotus Notes Domino, we created a system that would accept new ideas from an employee, track it through research, refinement, and approval through launching the product to the public.  This workflow ensured that the client's best new products and adjustments to existing products were brought to market quickly and efficiently.  The project ran for several years, iterating through continuous improvements as requirements changed, underlying technology advanced and new features were added.
        </p>
        <p>
            Since LotusScript is not a common web development platform, I had the opportunity to learn a completely new environment and language on joining this project.  This merged front-end skills with an entirely new back-end to deliver the final product.
        </p>
    </>
    return (
        <ProjectDetails
            title="LotusNotes application to track product development of consumer products"
            skills={skills}
            image={image}
            details={details}
        />
    )
}