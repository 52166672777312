import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Row, Col } from 'antd';
// import TopNav from './components/UI/TopNav'
// import Jumbotron from 'react-bootstrap/Jumbotron'
// import News from '../src/components/Pages/News/News'
// import Flex from '../src/components/Pages/Flex/Flex'
// import About from '../src/components/Pages/About/About'
// import CompoundInterest from './components/Pages/Interest/CompoundInterest'
// import WebVR from './components/Pages/WebVR/WebVR'
// import Weather from './components/Pages/Weather/Weather'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LDSCom from './components/Pages/Projects/LDSCom';
import CareerPlanner from './components/Pages/Projects/CareerPlanner';
import ShP from './components/Pages/Projects/ShP';
import USFed from './components/Pages/Projects/USFed';
import BigPharma from './components/Pages/Projects/BigPharma';
import Domino from './components/Pages/Projects/Domino';
import HTMLComponents from './components/Pages/Projects/HTMLComponents';
import HomeReno from './components/Pages/Projects/HomeReno';
import IT from './components/Pages/Projects/IT';
// import { Spin } from 'antd'
// import Projects from './components/Pages/Projects/Projects'
// import NotFoundPage from './components/Pages/NotFoundPage'

// import ReactGA from 'react-ga';

const Jumbotron = React.lazy(() => import('react-bootstrap/Jumbotron'));
const TopNav = React.lazy(() => import('./components/UI/TopNav'));
const Projects = React.lazy(() => import('./components/Pages/Projects/Projects'));
const About = React.lazy(() => import('./components/Pages/About/About'));
const Weather = React.lazy(() => import('./components/Pages/Weather/Weather'));
const Flex = React.lazy(() => import('./components/Pages/Flex/Flex'));
const News = React.lazy(() => import('./components/Pages/News/News'));
// const CompoundInterest = React.lazy(() => import('./components/Pages/Interest/CompoundInterest'));
const NotFoundPage = React.lazy(() => import('./components/Pages/NotFoundPage'));
// const TextShadows = React.lazy(() => import('./components/Pages/CSS/TextShadow'));
// const Animation = React.lazy(() => import('./components/Pages/CSS/Animation'));
// const Speech = React.lazy(() => import('./components/Pages/Speech/Speech'));
// const ThreeDCSS = React.lazy(() => import('./components/Pages/3DCSS/ThreeDCSS'));


//getElementsByClassName
function App() {

  // ReactGA.initialize('G-26WLZLM82K');

  return (
    <Router>
      <Suspense fallback={<></>}>
        {/* <Affix>
          <TopNavAnt />
        </Affix> */}
        <TopNav />
        <Jumbotron fluid className="HomePage">
          <div className="jumbotronText">
            <h1 className='FancyHeader_Jumbotron'>Ed Mauro ~ Web Developer.</h1>
            <p>
              As a Web Developer at multiple consulting companies catering to Global 500 clients since 1995, I have implemented both public-facing and intranet sites for industries including pharmaceutical, banking, healthcare, federal government, and ecommerce.  Skills include HTML/CSS/JavaScript, jQuery, PHP, SQL, AJAX, JSON and WordPress.  <a href='/about'>More Details</a>
            </p>
          </div>
        </Jumbotron>
        <div className="container">
          <Row>
            <Col>

              <Switch>
                <Route exact path="/">
                  <Redirect to="/projects" />
                </Route>
                <Route exact path="/projects">
                  <Projects title="Featured Projects" />
                </Route>
                <Route exact path="/projects/LDSCom">
                  <LDSCom />
                </Route>
                <Route exact path="/projects/CareerPlanner">
                  <CareerPlanner />
                </Route>
                <Route exact path="/projects/ShP">
                  <ShP />
                </Route>
                <Route exact path="/projects/USFed">
                  <USFed />
                </Route>
                <Route exact path="/projects/BigPharma">
                  <BigPharma />
                </Route>
                <Route exact path="/projects/Domino">
                  <Domino />
                </Route>
                <Route exact path="/projects/HTMLComponents">
                  <HTMLComponents />
                </Route>
                <Route exact path="/projects/HomeReno">
                  <HomeReno />
                </Route>
                <Route exact path="/projects/IT">
                  <IT />
                </Route>
                <Route path="/news">
                  <News title='Industry News' />
                </Route>
                {/* <Route path="/utils/textshadow">
                <h1>Text Shadows</h1>
              </Route> */}
                {/* <Route path="/demos/flexbox">
                  <Flex title="FlexBox Explorer" />
                </Route> */}
                {/* <Route path="/demos/weather">
                  <Weather title="Weather" />
                </Route> */}
                {/* <Route path="/demos/interest">
                  <CompoundInterest title="Compound Interest" />
                </Route> */}
                <Route path="/about">
                  <About title="About Ed" />
                </Route>
                {/* <Route path="/demos/threedcss">
                  <ThreeDCSS title="About Ed" />
                </Route> */}
                <Route path="/404" />
                <NotFoundPage />
              </Switch>

            </Col>
          </Row>

        </div>
      </Suspense>
    </Router>
  );
}

export default App;
