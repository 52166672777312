import ProjectDetails from "./ProjectDetails";
export default function CareerPlanner(props) {
    const skills =
        [
            'Sole Developer',
            'VB.Net',
            'UI/UX Design',
            'Homegrown Learning Management System',
            'Homegrown Content Management System',
            'Client Relationship',
            'Database Integration',

        ];
    const image = '/img/tile_notebook.jpg';
    const details = <>
        <p>
            A major payroll company needed to encourage younger new-hires to stay with them rather than leave after a short tenure. To do this, they wanted to demonstate that there was a career path available from their entry-level job. We created a Career Planning site which allowed them to visually map out their potential path via promotions and laterals. There were curated 'common paths' as well as the ability to create their own path using typical exits from each position to others.
        </p>
        <p>
            After that launched, the company returned to us for a custom learning management system (LMS). We first created a homegrown Content Management System to house snippets of reusable and easily editable content, so that the client could modify the site's wording after delivery. The LMS itself collected curated training courses both internal and external to the company, as well as allowed an employee to add links to courses they discovered on their own. This was all presented to both the employee and their manager in an easy to follow training plan that could be both tracked and altered as needed.
        </p>
        <p>
            I was the sole developer for both projects, requiring me to interact with multiple departments within the client and maintain the relationship with our client point of contact.  Working between Human Resources, their database specialist, and the Subject Matter Experts on various aspects of the project, I delivered the product itself, technical documentation and user training for all involved.
        </p>
    </>
    return (
        <ProjectDetails
            title="Career Planning Site and homegrown LMS for a major US payroll company"
            skills={skills}
            image={image}
            details={details}
        />
    )
}