import ProjectDetails from "./ProjectDetails";
export default function USFed(props) {
    const skills =
        [
            'SharePoint Templates',
            'VB.Net',
            'Document and Content Migration',
            'Federal Agency'

        ];
    const image = '/img/tile_DC.jpg';
    const details = <>
        <p>
            A branch of the US Federal government contracted my employer to design and implement an intranet portal for their employees.  The site was to be built in their existing SharePoint environment, using custom VB.Net Masterpages and Templates.  When the design was prepared, I was brought into the team to convert the layouts into Masterpages and Templates to facilitate the implementation using our design.  Using VB.Net and Visual Studio, I created the frameworks required to build the site, transforming the requirements into a set of scaffolding to build the site on.
            </p>
        <p>
            Next, I instantiated the pages, migrated the existing and updated content, and migrated the documents into appropriate Document Libraries.  These documents were then linked within the content, as well as made available by custom views of the Document Library and embedded search results surfaced on the pages.
        </p>
    </>
    return (
        <ProjectDetails
            title="SharePoint Templates and Multi-year Maintenance"
            skills={skills}
            image={image}
            details={details}
        />
    )
}