import ProjectDetails from "./ProjectDetails";
export default function BigPharma(props) {
    const skills =
        [
            'Pharmaceutical Industry Experience',
            'VB.Net',
            'Document and Content Migration',
            'Client Partnership',
            'Banner Advertisements',
            'Email Templates'

        ];
    const image = '/img/tile_pharma.jpg';
    const details = <>
        <p>
            With several major pharmaceutical companies, my employer had contracts to design, launch and maintain marketing/informational sites for their various vaccines, treatments and public disease education sites.  The sites contained content related to the diseases, prescribing information, contraindications, fair balance statements and promotional campaigns.  Because of the nature of the material, strict guidelines were in place requiring adherance with FDA policy in addition to the companies' own branding.
        </p>
        <p>
            Supporting those contracts, I coded and maintained B2C sites, banner advertisements and email templates for the clients' marketing campaigns. Special care had to be taken for the inclusion and display requirements of the prescribing information and fair balance statements related to any advertised product, as well as coordinating with the licensed doctor on retainer.
        </p>
    </>
    return (
        <ProjectDetails
            title="Public marketing sites for major pharmaceutical companies"
            skills={skills}
            image={image}
            details={details}
        />
    )
}